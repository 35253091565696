var currentURL = window.location.href;

if (currentURL.includes("wedding-dress")){
    var filterBoxes = document.querySelectorAll('.filter-box');

    filterBoxes.forEach(function(box) {
        var variants = box.querySelectorAll('.filter-variant');

        var coutureVariant = null;
        variants.forEach(function(variant) {
            if (variant.textContent.includes('Couture')) {
                coutureVariant = variant;
            }
        });

        if (coutureVariant) {
            box.insertBefore(coutureVariant, box.firstChild);
        }
    });
}
