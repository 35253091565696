document.addEventListener("turbo:load", function(){
  var currentURL = new URL(window.location.href);

  if (currentURL.href.includes("contacts")) {
    var elements = document.querySelectorAll('.contacts__nav-item');
    var innerBlocks = document.querySelectorAll('.inner-block');
    var selectElement = document.querySelector('.open-tab');

    function setActiveTab(index) {
      elements.forEach(function(el) {
        el.classList.remove('active');
      });

      innerBlocks.forEach(function(block) {
        block.style.display = 'none';
      });

      elements[index].classList.add('active');
      innerBlocks[index].style.display = 'block';
    }

    elements.forEach(function(element, index) {
      element.addEventListener('click', function() {
        setActiveTab(index);
      });
    });

    var tabParam = currentURL.searchParams.get('tab');
    var optionParam = currentURL.searchParams.get('option');

    if (tabParam !== null) {
      setActiveTab(parseInt(tabParam, 10) - 1);
    } else {
      setActiveTab(0);
    }

    if (optionParam !== null && selectElement) {
      selectElement.selectedIndex = parseInt(optionParam, 10) - 1;
    }
  }
});
