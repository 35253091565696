document.addEventListener("turbo:load", function(){
  var currentURL = window.location.href;

  if (currentURL.includes("password")) {
    var modal = document.querySelector('.forgot-modal')
    var modalBg = document.querySelector('.popup-bg')
    var openModal = document.querySelector('.forgot-page');
    var closeModal = document.querySelector('.close-button');

    openModal.addEventListener('click', function() {
      modal.classList.add('active');
      modalBg.classList.add('active');
    })
    closeModal.addEventListener('click', function() {
      modal.classList.remove('active');
      modalBg.classList.remove('active');
    })
    modalBg.addEventListener('click', function() {
      modal.classList.remove('active');
      modalBg.classList.remove('active');
    })
  }
});
