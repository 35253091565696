document.addEventListener('DOMContentLoaded', function() {
  var currentURL = window.location.href;
  if (currentURL.includes('contacts')) {
    initializeIntlTelInput('fitting_appointment_phone');
    initializeIntlTelInput('order_service_phone');
    initializeIntlTelInput('write_us_phone');
  }
});

function initializeIntlTelInput(elementId) {
  const input = document.getElementById(elementId);
  window.intlTelInput(input, {
    initialCountry: 'auto',
    geoIpLookup: callback => {
      fetch('https://ipapi.co/json')
        .then(res => res.json())
        .then(data => callback(data.country_code))
        .catch(() => callback("us"));
    },
    hiddenInput: 'phone_number',
    separateDialCode: true,
    utilsScript: '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js'
  });
}
