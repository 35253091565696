import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import "./controllers"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

require("fslightbox");
require("./packs/fancybox");

require("./packs/header"); // code for all pages
require("./packs/sliders");
require("./packs/scrolling"); // code for home and become a partner pages
require("./packs/contacts");  // code for contacts and become a partner pages
require("./packs/session");
require("./packs/video");
require("./packs/validation");
require("./packs/phone-input");
require("./packs/animation");
require("./packs/select");
require("./packs/sort-collection-filter");
require("./packs/banner");
